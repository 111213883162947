// Vendors
import '@fortawesome/fontawesome-free/css/all.css'
import 'animate.css'

// import main SASS file
import '../../styles/scss/style.scss'

// Import Foundation
import './init-foundation'

// Load JS modules to import at runtime
import './modules/globals'
import './modules/lazyload'
import './modules/forms'
import './modules/ie'
import './modules/heroSlider'
import './modules/parallax'
import './modules/testimonials'
import './modules/fancybox'

// Load JS modules to import upon window instersection
// Loads on first selector object 
// [Immediate] if false, module imports on 75% of window intersection
var modules = [
    {class: '.selector', loaded: false, immediate: true, instersectionRatioDesktop: 0.50, instersectionRatioMobile: 0.25},
    {class: '.three-column-blocks', loaded: false, immediate: false, instersectionRatioDesktop: 0.50, instersectionRatioMobile: 0.25},
    {class: '.blog-single .content-container', loaded: false, immediate: false, instersectionRatioDesktop: 0.25, instersectionRatioMobile: 0.25},
    {class: '.quicklinks', loaded: false, immediate: false, instersectionRatioDesktop: 0.40, instersectionRatioMobile: 0.25},
    {class: '.copy-video', loaded: false, immediate: false, instersectionRatioDesktop: 0.50, instersectionRatioMobile: 0.25},
    {class: '.schedule-appointment .content-block', loaded: false, immediate: false, instersectionRatioDesktop: 0.25, instersectionRatioMobile: 0.25},
    {class: '.kid', loaded: false, immediate: false, instersectionRatioDesktop: 0.25, instersectionRatioMobile: 0.25},
    {class: '.doctor-callouts', loaded: false, immediate: false, instersectionRatioDesktop: 0.40, instersectionRatioMobile: 0.75},
    {class: '.bio', loaded: false, immediate: true},
    {class: '.mighty-mouth-club .content-banner', loaded: false, immediate: true},
    {class: '.what-to-expect', loaded: false, immediate: true},
    {class: '.smile-savings-club', loaded: false, immediate: true},
    {class: '.tabs-template', loaded: false, immediate: true},
    {class: '.general-content', loaded: false, immediate: true},
    {class: '.contact', loaded: false, immediate: true},
    {class: '.forms', loaded: false, immediate: true},
    {class: '.categories', loaded: false, immediate: true}           
]

document.addEventListener("DOMContentLoaded", function () {
    
    modules.forEach((obj, index) => {
        var moduleSelector = document.querySelectorAll(obj.class)

        // Run immediately on intersect
        if ("IntersectionObserver" in window && obj.immediate) {
            let moduleObserverImmediate = new IntersectionObserver(function (entries, observer) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {

                        // // EXAMPLE
                        // if (obj.class === '.selecter' && !obj.loaded) {
                        //     import('path-to-module').then(function(module) {
                        //         // Render module
                        //         module.render()
                        //         obj.loaded = true;
                        //         moduleSelector.forEach((module, index) => {
                        //             moduleObserverImmediate.unobserve(module, {
                        //                 threshold: 0.0
                        //             })
                        //         })
                        //     })
                        // }

                        if (obj.class === '.bio' && !obj.loaded) {
                            import('./modules/bio').then(function(module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserverImmediate.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        if (obj.class === '.mighty-mouth-club .content-banner' && !obj.loaded) {
                            import('./modules/mightyMouthClub').then(function(module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserverImmediate.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        if (obj.class === '.what-to-expect' && !obj.loaded) {
                            import('./modules/whatToExpect').then(function(module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserverImmediate.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        if (obj.class === '.smile-savings-club' && !obj.loaded) {
                            import('./modules/smileSavings').then(function(module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserverImmediate.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }
                        
                        if (obj.class === '.tabs-template' && !obj.loaded) {
                            import('./modules/tabs').then(function(module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserverImmediate.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        if (obj.class === '.general-content' && !obj.loaded) {
                            import('./modules/copyVideo').then(function(module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserverImmediate.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        if (obj.class === '.contact' && !obj.loaded) {
                            import('./modules/contact').then(function(module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserverImmediate.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        if (obj.class === '.forms' && !obj.loaded) {
                            import('./modules/contact').then(function(module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserverImmediate.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        if (obj.class === '.categories' && !obj.loaded) {
                            import('./modules/blog').then(function(module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserverImmediate.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }
                    }

                })
            })

            if (moduleSelector !== undefined) {
                moduleSelector.forEach((module, index) => {
                    moduleObserverImmediate.observe(module, {
                        threshold: 0.0
                    })
                })
            }

        } else if ("IntersectionObserver" in window && !obj.immediate) {
            var thresholdTarget = window.outerWidth > 1024 ? obj.instersectionRatioDesktop : obj.instersectionRatioMobile
            // Run when section is halfway in the browser
            let moduleObserver = new IntersectionObserver(function (entries, observer) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {

                        if (entry.intersectionRatio >= thresholdTarget) {
                            // // EXAMPLE
                            // if (obj.class === '.selecter' && !obj.loaded) {
                            //     import('path-to-module').then(function(module) {
                            //         // Render module
                            //         module.render()
                            //         obj.loaded = true;
                            //         moduleSelector.forEach((module, index) => {
                            //             moduleObserver.unobserve(module, {
                            //                 threshold: 0.0
                            //             })
                            //         })
                            //     })
                            // }
    
                            if (obj.class === '.three-column-blocks' && !obj.loaded) {
                                import('./modules/threeColumnBlocks').then(function(module) {
                                    // Render module
                                    module.render()
                                    obj.loaded = true;
                                    moduleSelector.forEach((module, index) => {
                                        moduleObserver.unobserve(module)
                                    })
                                })
                            }

                            if (obj.class === '.quicklinks' && !obj.loaded) {
                                import('./modules/quicklinks').then(function(module) {
                                    // Render module
                                    module.render(index)
                                    obj.loaded = true;
                                    moduleSelector.forEach((module, index) => {
                                        moduleObserver.unobserve(module)
                                    })
                                })
                            }

                            if (obj.class === '.copy-video' && !obj.loaded) {
                                import('./modules/copyVideo').then(function(module) {
                                    // Render module
                                    module.render(index)
                                    obj.loaded = true;
                                    moduleSelector.forEach((module, index) => {
                                        moduleObserver.unobserve(module)
                                    })
                                })
                            }

                            if (obj.class === '.schedule-appointment .content-block' && !obj.loaded) {
                                import('./modules/scheduleAppointment').then(function(module) {
                                    // Render module
                                    module.render(index)
                                    obj.loaded = true;
                                    moduleSelector.forEach((module, index) => {
                                        moduleObserver.unobserve(module)
                                    })
                                })
                            }

                            if (obj.class === '.kid' && !obj.loaded) {
                                import('./modules/footer').then(function(module) {
                                    // Render module
                                    module.render(index)
                                    obj.loaded = true;
                                    moduleSelector.forEach((module, index) => {
                                        moduleObserver.unobserve(module)
                                    })
                                })
                            }

                            if (obj.class === '.doctor-callouts' && !obj.loaded) {
                                import('./modules/doctorCallouts').then(function(module) {
                                    // Render module
                                    module.render(index)
                                    obj.loaded = true;
                                    moduleSelector.forEach((module, index) => {
                                        moduleObserver.unobserve(module)
                                    })
                                })
                            }

                            if (obj.class === '.blog-single .content-container' && !obj.loaded) {
                                import('./modules/blogSingle').then(function(module) {
                                    // Render module
                                    module.render(index)
                                    obj.loaded = true;
                                    moduleSelector.forEach((module, index) => {
                                        moduleObserver.unobserve(module)
                                    })
                                })
                            }
    
                        }
                    } 
                })
            }, {threshold: [0.0, 0.25, 0.40, 0.50, 0.75, 1.0]})

            if (moduleSelector !== undefined) {
                moduleSelector.forEach((module, index) => {
                    moduleObserver.observe(module)
                })
            }
        }
    })

})

