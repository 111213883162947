import globals from './globals'
import {Scene} from 'scrollmagic'
import 'scrollmagic/scrollmagic/minified/plugins/animation.gsap.min'


// export const render = () => {
    var parallaxElements = document.querySelectorAll('.parallax'),
        offset = window.outerWidth < 640 ? 0 : 0,
        triggerHook = window.outerWidth < 640 ? 'onLeave' : 'onEnter'
        

    parallaxElements.forEach((obj, index) => {
        var transform
        if (obj.dataset.parallaxDirectionMobile === 'horizontal') {
            transform = window.outerWidth < 1024 ? {xPercent: -20} : {yPercent: -20}
        } else {
            transform = {yPercent: -20}
        }

        var parallaxAnimation = new TimelineMax()
        .to(obj.querySelectorAll('.parallax-target'), 0.5, transform)

        new Scene({
            triggerElement: obj,
            triggerHook: 'onEnter',
            offset: 0,
            duration: '150%'
        })
        .setTween(parallaxAnimation)
        .addTo(globals.controller)
    })

// }