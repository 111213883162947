import swiper from 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.min'
import $ from 'jquery'


document.addEventListener('DOMContentLoaded', () => {
  var heroSlider = document.querySelector('.hero-slider-container'),
      heroSliderMobile = document.querySelector('.hero-slider-content-slider'),
      hero_content_slider
 
  var hero_slider = new swiper('.hero-slider-container', {
    direction: 'vertical',
    loop: true,
    autoplay: {
      delay: 5000,
    },
    init: false,
    allowTouchMove: false,
    // If we need pagination
    pagination: {
      el: '.slide-controls',
      clickable: true,
    },
  })
  
  hero_slider.on('init', () => {
    // Stars Animation
    var slider = document.querySelectorAll('.hero-slider')

    if (slider !== undefined) {
      setTimeout(() => {
        var stars = document.getElementById('slider-stars')
        if (stars !== undefined && stars !== null) stars.classList.add('animated', 'jackInTheBox')
      }, 1000)
    }
  })

  hero_slider.init()


  if (document.body.contains(heroSliderMobile)) {
    if ( $(window).width() < 1024) {
      hero_content_slider = new swiper('.hero-slider-content-slider', {
        direction: 'horizontal',
        autoHeight: true,
        loop: false,
        init: false,
      })
    }
    hero_content_slider.init()

    hero_slider.on('slideChangeTransitionEnd', (e) => {
      hero_content_slider.slideTo(hero_slider.realIndex)
    })
  }
})